
import { defineComponent } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import CampaignsSmsDetailsHero from '@/components/pages/campaigns/details/sms/CampaignsSmsDetailsHero.vue'
import CampaignsDetailsProgress from '@/components/pages/campaigns/details/CampaignsDetailsProgress.vue'
import { useModes } from '@/compositions/modes'
import { campaignsSmsProgressInfos } from '@/definitions/campaigns/data'

export default defineComponent({
  components: {
    CampaignsDetailsProgress,
    CampaignsSmsDetailsHero,
    PageContent,
  },
  props: {
    hideWhiteBlock: {
      type: Boolean,
    },
  },
  setup() {
    const { isEmptyMode } = useModes()
    const breadcrumbs = [
      { label: 'Campaign', url: { name: 'base.campaigns.sms' } },
      { label: 'New Mercurial Vapor X' },
    ]

    return {
      breadcrumbs,
      campaignsSmsProgressInfos,
      isEmptyMode,
    }
  },
})
